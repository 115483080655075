.mobnav-main{
  /*slide animation*/
  animation: slide 0.5s;
  position: absolute;
  top: 0;
  z-index: 10000;
  width: 300px;
  background-color: #fff;
  position: absolute;
  height: 100%;
  transition: 0.5s;
  left: 0;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.5; }
}
.mobnav-dark-bg{
  animation: fadeIn 0.2s;
  top: 0;
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000;
}
.mobile-nav-icon{
  width: 150px;
  height: 40px;
}

.mobile-nav-top{
  padding: 15px;
  width: 105%;
  background-color: rgb(244, 240, 240);
}

.mobile-nav-link{
  text-align: start;
  padding: 10px;
  font-weight: 600;
  font-size: 15px;
}

.close-icon{
  margin-right: 0px;
  margin-left: 110px;
  color: red;
  font-size: 25px;;
}

.mob-nav-btn{
  width: 80%;
  background-color: #03B97C;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  margin:99% auto 10px auto;
  font-weight: 400;
}

.mob-nav-btn>i{
  margin-right: 5px;
}