@font-face {
  font-family: Jost;
  src: url('./Jost-SemiBold.ttf');
}
@font-face{
  font-family: Jost-Bold;
  src: url('./Jost-ExtraBold.ttf');
}
.cover{
  background-color: #F4F4F4;

}

.cover-leading{
  max-width: 500px;
}

.cover-leading>div{
  padding: 10px;
}

.cover-headline>h2{
  font-size: 2.75rem;
  font-family: Muli;
  text-align: left;
  font-weight: 900
}
.input-group-text{
  font-family: Muli;
  background-color: #03b97c;
  color: white;
}
 .cover-information{
  font-family: Muli;
  font-size: 18px;
  font-weight:500;
  flex-wrap: wrap;
  text-align: start;
 }
.input-group-text:hover{
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  background-color: #057751;
}

input{
  border-style: none !important;
  padding: 25px 25px 25px 45px!important;
}
input:focus{
  border-style:solid !important;
  border-color: #03b97c !important;
  box-shadow: none !important;
}
 .input-group>i{
  float: left;
  margin-right: -30px;
  margin-top: 18px;
  position: relative;
  z-index: 4;
  color: #c3c2c2;

 }
img{
  width: 40vw;
}
.counter{
  margin-left: -20px;
}
.counter>div{
  font-family: 'Jost';
  color: #03b97c;
  padding: 10px;
  margin-right: 25px;
  font-size: 40px;
  font-weight: 900;
}

.counter-subtitle{
  color: black;
  font-size: 15px;
}

.cover-footer{
  margin-top: 100px;
  margin-bottom: 0;
}
.single-cover-footer{
  width: 350px;
  min-width: 350px;
  height: max-content;
  padding: 10px;
  margin-right: 30px;
  background-color: white;
  border-radius: 10px;
}


.single-cover-footer>div{
  padding: 5px;
  text-align: start;
}
.single-cover-footer>div:nth-child(1){
  text-align: center;
  border-style: dotted;
  border-radius: 50%;
  width: 75px;
  padding: 15px;
}

.cover-footer-title{
  font-size: 18px;
}
