.service-information-section {
  padding: 10px;
}

.first-information-header {
  margin-bottom: 20px;
  text-align: start;
  font-family: 'Jost';
}
.service-information-description{
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-bottom: 10px;
  line-height: 25px;
  text-align: start;
}

.information-check-icon{
  background-color: #E4F7F1 ;
  color: #03B97C;
  font-size: 15px;
  margin-right: 20px;
  padding: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.information-check-icon>i{
  display: flex;
  justify-content: center;
  align-items: center;
}
.information-headlines>div{
  margin-bottom: 10px;
}

.enroll-btn{
  /* make animation ease in out differnet browser*/
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #03B97C;
  font-size: 14px;
  font-weight: 500;
  padding: 15px 30px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  border-color: #03B97C;
  border-style: solid;
  border-width: 1px;
}
.enroll-btn>div>a{
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  text-decoration: none;
}
.enroll-btn:hover{
  background-color: #fff;
}

.enroll-btn:hover>div>a{
  color: #03B97C;
}

.image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.attachment-full{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.information-second-icon{
  background-color: #F7F8F9 ;
  color: #03B97C;
  font-size: 15px;
  margin-right: 20px;
  padding: 25px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.information-second-icon>i{
  font-size: 20px;
}

.information-service-header{
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin-bottom: 5px;
  text-align: start;
}

.information-long-description{
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-bottom: 10px;
  line-height: 25px;
  text-align: start;
}