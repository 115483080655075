.card{
    width: 22em;
    margin: 1rem;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    background-color: #fff;
    color: #000;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.card>img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.card-text{
    font-size: 15px;
}

.card-text>i{
  color: #03B97C;
  margin-right: 5px;
}

.instructorsbtn{
    font-family: 'Muli';
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    color: #03B97C;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 15px;
    transition: all 0.3s ease-out;
    border: 1px solid #03B97C;
}

.instructorsbtn:hover{
    background-color: #03B97C;
    color: #fff;
}