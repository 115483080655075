@font-face {
  font-family: Muli;
  src: url(muli.regular.ttf);
}

@media (min-width: 1200px){
.pre {
    margin-right: 0.625rem;
}}
.pre {
    margin-right: 0.4rem;
}

.fa, .fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.signIn{
  background-color: #03b97c;
  border-color: #03b97c;
  color: #fff;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  display: inline-block;
  font-weight: 400;
  line-height: 1.8;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.65rem 1.875rem;
  font-size: 0.9375rem;
  border-radius: 0.3rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.signIn:hover{
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  background-color: #fff;
  border-color: #03b97c;
  color: #03b97c;
}
.signIn>a{
  color: #fff;
  text-align: center;
  text-decoration: none;
}
.signIn:hover>a{
  text-align: center;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  color: #03b97c;
}
.logo-main{
  box-sizing: border-box;
  
}
.logo-main>img{
  width: 155px;
  height: 40px;
}

.navbar-nav>li{
  margin-right: 15px;
  font-family:'Muli';
  font-weight: 800;
  font-size: 13px;
}
.nav-link{
  color: black !important;
}

.nav-item>a:hover{
  color: #03b97c !important;
}

.nav-item:nth-child(1)>a{
  color: #03b97c !important;
}





